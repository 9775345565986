import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { uploadManyVideoFiles, reset} from "../../../features/video_team/videosUpload/videosSlice";
import { getSignedUrl} from "../../../features/video_team/files/fileSlice";
import { getSingleVideo, setChapters} from "../../../features/video_team/videosUpload/videosSlice";

import CustomToast from "../../shared/CustomToast";
import Downdloader from '../../shared/Loaders/Downloader'







function GlobalVideoFileUploader({videoId, language_code, prompt, output, transcriber}) {

    const [files, setFiles] = useState([]);
    const [id, setId] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [fileError, setFileError] = useState(false);
    const {isError, message } = useSelector((state) => state.videos);
    const dispatch = useDispatch();
    const [loadingMessage, setLoadingMessage] = useState('Generating summary from video...'); // Inicializa el estado


    useEffect(() => {
        setId(videoId);  
    },[videoId])
     
    const handleFileChange = (event) => {
        const newFiles = event.target.files;
        setFiles(newFiles);
    };
      

    const handleRemoveFile = (indexToRemove) => {
        setFileError(false)
        setFiles(prevFiles => {
            const newFiles = [...prevFiles];
            newFiles.splice(indexToRemove, 1);
            return newFiles;
        });
    };
    

    const handleUpload = async()=> {
     
        const chapterArray = [];
       
        for (let i = 0; i < files.length; i++) {
            setLoading(true);
            const chapter = {
                title: files[i].name.replace(/['"]/g, ""),
                video_file: new File([files[i]], files[i].name.replace(/['"]/g, "")),
            };
            chapterArray.push(chapter);
            const file = files[i];
            await sendBrowserGCS(file,chapterArray);
        }
        setFileError(false)

        
    };

    // const sendBrowserGCS = async(file, chapterArray)=>{

    //     const filename = file.name.replace(/['"]/g, "").replace (/^/,id+'_');
    //     const data_sign= {
    //         filename : filename,
    //         filetype : file.type,
    //         folder_name : 'videos',
    //     }

    //     await dispatch(getSignedUrl(data_sign))
    //     .unwrap()
    //     .then((response) => {
    //         console.log(response)
    //         if(!response.success){alert('error')};

    //         const xhr = new XMLHttpRequest();
    //         xhr.open("PUT", response.url, true);
    //         xhr.onload = () => {
    //         const status = xhr.status;
    //         console.log(xhr);
    //         if (status === 200) {
    //             // alert("File is uploaded");
    //             setLoadingMessage(`File uploaded`);

    //             const data = {
    //                 data_sign: data_sign,
    //                 prompt: prompt,
    //                 transcriber: transcriber,
    //                 id_video: id,
    //                 output: output,
    //                 chaptersArray: chapterArray,
    //                 language_code: language_code,
    //                 is_global: true,
    //               };
    //               console.log(data)
    //               setLoadingMessage(`Trascribing ...`);
    //               dispatch(uploadManyVideoFiles(data))
    //                   .unwrap()
    //                   .then((response) => {
    //                     //   dispatch(getSingleVideoCopy(id))
    //                      setLoading(false);
    //                      dispatch(getSingleVideo(id))
    //                      dispatch(reset())
    //                      // Show the custom toast with the link
    //                      toast.success(
    //                          <CustomToast
    //                              message="Your files are ready. Click to view!"
    //                              linkTo={`/videos/video/${id}`}
    //                          />,
    //                          { autoClose: 7000 }
    //                      )
    //                     //  window.location.href =(`/videos/video/${id}`)

        
    //                   })
    //                   .catch((error) => {
    //                       toast.error(error)
    //                       dispatch(reset())
    //                       setLoading(false);
    //                   })
    //                   .then(() => {
    //                       dispatch(reset());
    //                       setLoading(false);
    //                   }); 
    //         } else {
    //             alert(`Error status: ${status}`);
    //             setLoading(false);
    //         }
    //         };

    //         xhr.upload.addEventListener("progress", (event) => {
    //             if (event.lengthComputable) {
    //                 const percentage = Math.round((event.loaded * 100) / event.total);
    //                 console.log(`Subida: ${percentage}%`);
    //                 setLoadingMessage(`Uploading file... ${percentage}%`);
    //             }
    //         });

    //         xhr.onerror = (error) => {
    //             alert(error);
    //             setLoading(false);
    //         };
    //         xhr.setRequestHeader('Content-Type', file.type);
    //         xhr.send(file)
    //     });
    // }

    const sendBrowserGCS = async (file, chapterArray) => {
        const filename = file.name.replace(/['"]/g, "").replace(/^/, id + '_');
        const data_sign = {
            filename: filename,
            filetype: file.type,
            folder_name: 'videos',
        };
    
        // Espera a que `getSignedUrl` se resuelva
        const response = await dispatch(getSignedUrl(data_sign)).unwrap();
    
        if (!response.success) {
            alert('Error al obtener la URL firmada');
            return;
        }
    
        // Crea una promesa para esperar que `XMLHttpRequest` termine
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", response.url, true);
    
            xhr.onload = () => {
                if (xhr.status === 200) {
                    setLoadingMessage(`File uploaded`);
    
                    const data = {
                        data_sign: data_sign,
                        prompt: prompt,
                        transcriber: transcriber,
                        id_video: id,
                        output: output,
                        chaptersArray: chapterArray,
                        language_code: language_code,
                        is_global: true,
                    };
    
                    setLoadingMessage(`Transcribing ...`);
                    
                    // Espera a que la transcripción termine antes de resolver la promesa
                    dispatch(uploadManyVideoFiles(data))
                        .unwrap()
                        .then(() => {
                            setLoading(false);
                            dispatch(getSingleVideo(id));
                            dispatch(reset());
    
                            // Mostrar mensaje de éxito
                            toast.success(
                                <CustomToast
                                    message="Your files are ready. Click to view!"
                                    linkTo={`/videos/video/${id}`}
                                />,
                                { autoClose: 7000 }
                            );
                            resolve(); // Resuelve la promesa al finalizar correctamente
                        })
                        .catch((error) => {
                            toast.error(error);
                            dispatch(reset());
                            setLoading(false);
                            reject(error); // Rechaza la promesa si hay un error
                        });
                } else {
                    setLoading(false);
                    reject(new Error(`Error status: ${xhr.status}`));
                }
            };
    
            xhr.onerror = (error) => {
                setLoading(false);
                reject(error); // Rechaza la promesa si hay un error en la solicitud
            };
    
            xhr.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    const percentage = Math.round((event.loaded * 100) / event.total);
                    setLoadingMessage(`Uploading file... ${percentage}%`);
                }
            });
    
            xhr.setRequestHeader('Content-Type', file.type);
            xhr.send(file); // Envía el archivo
        });
    };
    


    if(isLoading){
        return (
            <Downdloader text={loadingMessage} />
        )
    }

    
    return (
        <div className='uploader-container'>
                {transcriber === 'google' ? (
                    <span style={{color: fileError ? 'red' : 'black', marginTop: '10px'}}>Files must be flac or wav</span>
                ) : transcriber === 'whisper'? (
                    <span style={{color: fileError ? 'red' : 'black', marginTop: '10px'}}></span>
                ): null}
                <input type="file" accept="video/mp4" multiple onChange={handleFileChange} />
            <div>
            {files &&
                Array.from(files).map((file, index) => (
                <div className='file-uploader-actions' key={index}>
                    <p>{file.name}</p>
                    <button className='btn' onClick={() => handleRemoveFile(index)}>Remove</button>
                </div>
                ))}
            </div>
            <button className='btn' onClick={handleUpload} disabled={fileError}>SAVE Video</button>
            {fileError && (
                <span style={{color: 'red', marginTop: '10px'}}>Some files are not wav/flac. Please check again</span>
            )}
            {isLoading && <div>Uploading...</div>}
            {isError && <div>Error: {message}</div>}
        </div>
    );
}

export default GlobalVideoFileUploader