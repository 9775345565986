import { useState } from "react"
import { useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { Link, useParams, useNavigate, useLocation} from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from "../../components/shared/Loaders/Spinner";
import Downloader from "../../components/shared/Loaders/Downloader";
import AudioFileUploader from '../../components/video/files/AudioFileUploader'
import { getSingleVideo, reset } from "../../features/video_team/videosUpload/videosSlice";
import { getChaptersByVideoId } from "../../features/video_team/chapters/chaptersSlice";
import './SingleVideo.css';
import useDelete from '../../hooks/useDelete';
import SingleChapter from "../../components/video/chaperts/SingleChapter";
import FileUploader from "../../components/video/files/FileUploader";
import DownloadFilesButton from "../../components/video/shared/DownloadFilesButton";
import TranscribedAudioButton from "../../components/video/shared/TranscribedAudioButton";
import ChangeLnaguage from "../../components/video/shared/ChangeLanguage";
import {updateTitle,updatePrompt, syncChapterFromDacast} from "../../features/video_team/videosUpload/videosSlice";
import DacastVideoUploader from '../../components/video/files/DacastVideoUploader'
import GlobalVideoFileUploader from "../../components/video/files/GlobalVideoFileUploader"




function SingleVideo() {
  const { id } = useParams();
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

 

  // custom hook to open srt files
  const {handleDelete} = useDelete();

  // Set codes of languages trhat are already translated, plus code of masterclass original language and pass them to child component SelectLanguages
  const [codes, setCodes] = useState();
  const [addChapters, setAddChapters] = useState(false);
  const [changeLanguage, setChangeLanguage] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [srtFile, setSrtFile] = useState(false);
  const [audioFile, setAudioFile] = useState(false);
  const [video, setVideo] = useState(null);
  const [changeTitle, setChangeTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(false);
  const [dacast_chapters, setDacastChapters] = useState([]);
  const [transcriber, setTranscriber] = useState('whisper');
  const [promptValue, setPrompt] = useState('');
  const [radioButtonValue, setRadioButtonValue] = useState('srt');
  const [notTranscribed, setNotTranscribed] = useState(0);
  const [transcribed, setTranscribed] = useState(0);
  const [transcribe_array, setTranscribeArray] = useState(0);
  const [videoFile, setVideoFile] = useState(false);
  const [dacastFile, setDacastFile] = useState(true);
  const [dacastID, setDacastID] = useState('');


  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.videos);
  const { video_chapters} = useSelector((state) => state.chapters);
  const { translatedLanguages} = useSelector((state) => state.languages);
  const { isLoading: transcriptionsIsLoading } = useSelector((state) => state.transcriptions);
  const { isLoading: translationsIsLoading } = useSelector((state) => state.translations);
  const [dacastListVisible, setDacastListVisible] = useState(false);


   // if there are translations set them to the state
   useEffect(() => {
    let array_codes = [];
    if (video){
      array_codes.push(video.original_language_code);
      console.log(video);
      setPrompt(video.prompt);
      setCodes(array_codes);
      setNewTitle(video.masterclass);
    }
  }, [video]);

  

  // get all data from this on masterclass
  useEffect(() => {
    if(id){
        dispatch(getSingleVideo(id))
        .unwrap()
        .then((video_restult) => {
          setVideo(video_restult);
        })
        .catch((error) => {
          toast.error(error)
          navigate('/videos/home')
          // dispatch(reset());
      });
    }
    return () => {
      setVideo(null); // clear state when unmounting
    };


  }, [dispatch, id, navigate])


  // get all data from this on masterclass
  useEffect(() => {
    if(id){
        dispatch(getChaptersByVideoId(id))
        .unwrap()
        .then((chapter_restult) => {
          
        })
        .catch((error) => {
          toast.error(error)
          navigate('/videos/home')
          // dispatch(reset());
      });

      

    }
  }, [dispatch, id, navigate])

  useEffect(() => {
  
    if(id && video && !video.dacast_id){
      dispatch(syncChapterFromDacast({
        video_id: id
      }))
      .unwrap()
      .then((dacast_result) => {
        // console.log(dacast_result);
        setDacastChapters(dacast_result);
      })
      .catch((error) => {
          // toast.error(error)
      });
    }

  }, [id,video])

  useEffect(() => {
    const arrayChapters = [];
    if(video_chapters){
      for(let i = 0; i < video_chapters.length; i++){
        const object = {
          id: video_chapters[i].id,
          filename: video_chapters[i].filename,
        }

        arrayChapters.push(object);

      }
      setFileData(arrayChapters);
    }

   

  }, [video_chapters])



  // if theres an error display message from the error
  useEffect(() =>{
    if(isError){
        toast.error(message)
    }
    dispatch(reset());

}, [isError, isSuccess, dispatch, message])


const handleChangeSelect = (event) => {
  setTranscriber(event.target.value)
}

const handleRadioButtonChange = (event) => {
  setRadioButtonValue(event.target.value);
};

const onChangePrompt = (event) => {
  setPrompt(event.target.value)
}

// Change masterclass title

const onChange = (e) => {
  e.preventDefault();
  const newtitle = e.target.value;

  setNewTitle(newtitle)
}

const changeMasterclassPrompt  = () => {
  const data = {
    id: video.id,
    prompt: promptValue,
  }
  dispatch(updatePrompt(data))
      .unwrap()
      .then(() => {
        dispatch(getSingleVideo(video.id))
        .unwrap()
        .then((video_restult) => {
          setChangeTitle(false)
          setVideo(video_restult);
        })
        
      })
      .catch((error) => {
        toast.error(error)
    });
}


const changeMasterclassTitle = () => {
  const data = {
    id: video.id,
    title: newTitle,
  }
  dispatch(updateTitle(data))
      .unwrap()
      .then(() => {
        dispatch(getSingleVideo(video.id))
        .unwrap()
        .then((video_restult) => {
          setChangeTitle(false)
          setVideo(video_restult);
        })
        
      })
      .catch((error) => {
        toast.error(error)
    });
}

const handleChangeDacast = (e) => {
  e.preventDefault();
  const dacast_id = e.target.value;
  setDacastID(dacast_id);


}


// if there are chapters that are not transcribed count them

useEffect(() => {
  if(video_chapters){
    const countChaptersTranscribed = video_chapters.filter((chapt) => chapt.transcribed === 1);
    const countChaptersNotTranscribed = video_chapters.filter((chapt) => chapt.transcribed === 0);
    setNotTranscribed(countChaptersNotTranscribed.length)
    setTranscribed(countChaptersTranscribed.length)
    let to_transcribe = [];
    for(let i = 0; i < countChaptersNotTranscribed.length; i++){
      const data = {}
      data.id = countChaptersNotTranscribed[i].id
      data.audio_name = countChaptersNotTranscribed[i].audio_name
      to_transcribe.push(data);
    }

    setTranscribeArray(to_transcribe)
  }
}, [video_chapters])


const handlerDacastChapters = (e) => {

  setDacastListVisible(!dacastListVisible);


}

  if(isLoading || !id ) {
    return (
      <Spinner/>
    )
  }
  

  if(location.state && location.state.error){
    return (
      <div className="error">{location.state.error}</div>
    )
  }

  
if(video){
  return (
        
    <div className="single-video-container">
      <div className="single-video-title">
        {changeTitle ? (
          <div className="change-title">
            <button onClick={() => {setChangeTitle(!changeTitle); setNewTitle(false);}} className="btn btn-reverse btn-title">close</button> 
              <input onChange={onChange} type="text" className="input-form-center" placeholder="Masterclass title" 
                value={newTitle}>
              </input> 
            <button onClick={changeMasterclassTitle} type="submit" className="btn btn-reverse btn-title">SAVE</button>
          </div>
        ) : (
          <h1 onClick={() => setChangeTitle(!changeTitle)} className="masterclass-title">{video.masterclass}</h1>
        )}
      </div>
      {!addChapters ? (
        <>
    

      {video_chapters && (
          <div className="chapters-dacast-wrapper">
          {!video.dacast_id && (<>
            <p className="chapter-count dacast-detection" onClick={handlerDacastChapters}>Dacast playlists found: {dacast_chapters?(dacast_chapters.length):0}</p>
          </>)}
          
          {dacastListVisible && (
              <div className="dacast-playlits-wrap">
                {dacast_chapters?.map((playlist, index2) => (
                  <div  key={index2} className="dacast-wrapp">
                    <h4 >{playlist?.title}</h4>
                    <ul className="dacast-list">
                      {playlist?.list?.map((elemento, index) => (
                      <li key={index}>
                        <a target="blank" href={`https://app.dacast.com/videos/${elemento.id}/general`}><u>{elemento.title}</u></a><br/><pre>{elemento.id}</pre>
                      </li>

                      ))}
                    </ul>
                  </div>
                ))}
              </div>
          )}
          </div>
        )}
        </>
      ) : ( <></> )} 
      <div className="single-video-header">
      
      {!addChapters ? (
        <>
        
        <div className="single-video-header-left">
        {video.dacast_id && (<>
        
        <p>Dacast ID:</p>
        <small>{video.dacast_id}</small><br/>
      
      </>)}
        <p className=" username">Author: {video.username}</p>
        {video_chapters && (
          <>
            <p className="chapter-count">Chapters: {video_chapters.length}</p>
            <p className="chapter-count">Not Transcribed: {notTranscribed}</p>
            <p className="chapter-count">Transcribed: {transcribed}</p>

            
          </>      
        )}
      </div>
      <div className="single-video-header-right"> 
      <button onClick={() => setChangeLanguage(!changeLanguage)} className="btn btn-reverse">change language</button> 
      {changeLanguage && <ChangeLnaguage/> }
          {video_chapters && (
            <Link
                to='/videos/translate' state={{translation_type: 'many', fileData: fileData, masterclass: video.masterclass, original_language_code: video.original_language_code, language_codes: translatedLanguages ? translatedLanguages : null}} className='btn btn-reverse create-translation'
                >
                TRANSLATE
            </Link>
          )}
          <button onClick={() => setAddChapters(!addChapters)} className="btn create-translation">ADD chapter</button>
          {video_chapters.length !== notTranscribed ? <DownloadFilesButton id_video={video.id}/> : null}
          <TranscribedAudioButton transcribe_array={transcribe_array} id_video={video.id}/>
          </div>
          </>
      ) : (
        <div className="uploader-container-center">
          {(transcriptionsIsLoading === false && translationsIsLoading === false) ? (
            <>
            <button onClick={() => setAddChapters(!addChapters)} className="btn btn-reverse btn-absolute">Close</button>
            <div className="create-buttons-container">
                <button className={audioFile ? 'btn' : 'btn btn-reverse'} onClick={() => { setAudioFile(!audioFile); setSrtFile(false); }}>UPLOAD AUDIO FILES</button>
                {/* <button className={srtFile ? 'btn' : 'btn btn-reverse'} onClick={() => { setSrtFile(!srtFile); setAudioFile(false); }}>UPLOAD SRT FILES</button> */}

                <button className={videoFile ? 'btn' : 'btn btn-reverse'} onClick={() => { setVideoFile(!videoFile); setAudioFile(false); setSrtFile(false); setDacastFile(false); }}>UPLOAD VIDEO FILE</button>
                <button className={srtFile ? 'btn' : 'btn btn-reverse'} onClick={() => { setSrtFile(!srtFile);setVideoFile(false); setAudioFile(false); setDacastFile(false); }}>UPLOAD SRT FILES</button>
                <button className={dacastFile ? 'btn' : 'btn btn-reverse'} onClick={() => { setDacastFile(!dacastFile); setSrtFile(false); setVideoFile(false); setAudioFile(false);  }}>UPLOAD FROM DACAST PLAYLIST ID</button>
                {(dacastFile) && (
                <input type="text" className="dacast-box" 
                            value={dacastID} 
                            placeholder="Dacast video id"
                            onChange={(e) => handleChangeDacast(e)} /> 
                )}
            </div>
             {(audioFile || videoFile || (dacastFile && dacastID) ) && (
                <>
                    <div className="chose-transcriber-container">
                        <h2>SELECT METHOD:</h2>
                        <select onChange={handleChangeSelect}>
                            <option value="" disabled >Choose transcriber</option>
                            <option value='google'>
                                GOOGLE
                            </option>
                            <option value='whisper' selected>
                                WHISPER
                            </option>
                            <option value='no-transcriber'>
                                NO TRANSCRIPTION
                            </option>
                        </select>
                    </div>
                    {transcriber === 'whisper' && (
                        <>
                            <div className="prompt-container">
                            <h2>Select output:</h2>
                                <label>
                                    <input
                                    type="radio"
                                    value="srt"
                                    checked={radioButtonValue === 'srt'}
                                    onChange={handleRadioButtonChange}
                                    />
                                    <span style={{ marginLeft: '10px' }}>srt</span>
                                </label>
                               
                            </div>                          
                        </>
                    )}
                     {(dacastFile && dacastID) && (
                        <DacastVideoUploader single={true} output={radioButtonValue} prompt={promptValue} transcriber={transcriber} videoId={video.id} language_code={video.original_language_code} dacast_id={dacastID}/>
                     )}
                    {(audioFile) && (
                        <AudioFileUploader output={radioButtonValue} prompt={promptValue} transcriber={transcriber} videoId={video.id} language_code={video.original_language_code} />
                    )}
                    {(videoFile) && (
                        <GlobalVideoFileUploader output={radioButtonValue} prompt={promptValue} transcriber={transcriber} videoId={video.id} language_code={video.original_language_code} />
                    )}
                   
                </>
            )}
            {(srtFile && !transcriptionsIsLoading) && (
              <>
                  <FileUploader videoId={video.id} upload={'files'}/>
              </>
            )}
            </>
          ): <Downloader/>}
      </div>
      )}    
      </div>
      <div className="single-video-header-center">
        {video && (
          <>
            <label className="single-video-header-center-label">Prompt</label><br/>
            <textarea
                  type='text'
                  className='form-control single-video-header-center-input'
                  id='prompt'
                  name='prompt'
                  style={{minWidth:'500px', minHeight:'120px', padding:'10px'}}
                  value={promptValue}
                  onChange={onChangePrompt}
                  placeholder='Add your own prompt'
            />
            <button onClick={changeMasterclassPrompt} type="submit" className="btn btn-reverse btn-title">Update prompt</button>
          </>
        )}
      
      </div>
      <SingleChapter codes={codes}/>
      
      <button style={{marginLeft: '60px'}} className="btn btn-reverse" onClick={() => handleDelete(video.id, 'video')}>
         DELETE Masterclass
      </button>

    </div>
  )
}
      
    }
    
    export default SingleVideo